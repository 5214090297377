import service from '@/services/modules/panel/chat'

export default {
  SEARCH_LIST_CHAT(_, params) {
    return new Promise((resolve, reject) => {
      service
        .searchListChat(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  SEARCH_LIST_MESSAGGE(_, params) {
    return new Promise((resolve, reject) => {
      service
        .searchChatListMessage(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  CREATE_MESSAGE(_, params) {
    return new Promise((resolve, reject) => {
      service
        .create(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  UPDATE_MESSAGE(_, params) {
    return new Promise((resolve, reject) => {
      service
        .update(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
