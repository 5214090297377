import service from '@/services/modules/panel/producto'

export default {
  PRODUCTO_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service
        .findAllProducto(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  PRODUCTO_UPDATE(_, params) {
    return new Promise((resolve, reject) => {
      service
        .update(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
