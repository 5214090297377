export default [
  {
    path: '/app/perfil',
    name: 'rt-panel-perfil',
    component: () => import('@/views/erp/profile/Profile.vue'),
    meta: {
      resource: 'rt-panel-perfil',
      pageTitle: 'Perfil',
      breadcrumb: [
        {
          text: 'Panel',
        },
        {
          text: 'Perfil',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/plataforma/info/atractivo',
    name: 'rt-panel-perfil-editar',
    component: () => import('@/views/erp/promociones/detalleficha/FichaAtractivoDetalle.vue'),
    meta: {
      resource: 'rt-panel-perfil-editar',
      pageTitle: 'Editar Atractivo',
      breadcrumb: [
        {
          text: 'Plataforma',
        },
        {
          text: 'Información Turística',
        },
        {
          text: 'Atractivos',
        },
        {
          text: 'Editar Atractivo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/promociones',
    name: 'rt-panel-promociones',
    component: () => import('@/views/erp/promociones/Promociones.vue'),
    meta: {

      contentClass: 'ecommerce-application',
    },
  },
  {
    path: '/apps/plataforma/info/empresa/:urlSlug',
    name: 'rt-panel-promociones-tipo-empresa',
    component: () => import('@/views/erp/plataforma/info/empresa/Empresa.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['Agencias', 'draft', 'starred', 'spam', 'trash'].includes(to.params.urlSlug)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/app/reservas',
    name: 'rt-panel-reservas',
    component: () => import('@/views/erp/reservas/ReservasList.vue'),
    meta: {
      resource: 'rt-panel-reservas',
      pageTitle: 'Reservas',
      breadcrumb: [
        {
          text: 'Panel',
        },
        {
          text: 'Reservas',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/plataforma/info/tipo-empresa',
    name: 'rt-panel-notificaciones',
    component: () => import('@/views/erp/plataforma/info/tipo_empresa/TipoEmpresaList.vue'),
    meta: {
      resource: 'rt-panel-notificaciones',
      pageTitle: 'Tipo de Empresa',
      breadcrumb: [
        {
          text: 'Plataforma',
        },
        {
          text: 'Información Turística',
        },
        {
          text: 'Tipo de Empresa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/productos',
    name: 'rt-panel-productos',
    component: () => import('@/views/erp/producto/Producto.vue'),
    meta: {
      resource: 'rt-panel-productos',
      pageTitle: 'Productos',
      breadcrumb: [
        {
          text: 'Panel',
        },
        {
          text: 'Productos',
        },
      ],
    },
  },
  {
    path: '/app/chat',
    name: 'rt-panel-bandeja',
    component: () => import('@/views/erp/chat/Chat.vue'),
    meta: {

      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',

    },
  },
]
