// import actions
import destinoAction from './actions/destino'
import categoriaRecursoAction from './actions/categoria_recurso'
import tipoRecursoAction from './actions/tipo_recurso'
import subTipoRecursoAction from './actions/subtipo_recurso'
import ubigeoAction from './actions/ubigeo'
import clasificacionAction from './actions/clasificacion'
import accesoRecursoAction from './actions/acceso_recurso'
import infraestructuraAction from './actions/infraestructura_recurso'
import actividadAction from './actions/actividad_recurso'
import servicioAction from './actions/servicios_recurso'
import tipoServicioTuristico from './actions/tipo_servicio_turistico'
// import getters
import sedeGetter from './getters/sede'

// import mutations
import sedeMutation from './mutations/sede'

// import states
import sedeState from './states/sede'

const actions = {
  ...servicioAction,
  ...actividadAction,
  ...infraestructuraAction,
  ...accesoRecursoAction,
  ...clasificacionAction,
  ...destinoAction,
  ...categoriaRecursoAction,
  ...tipoRecursoAction,
  ...subTipoRecursoAction,
  ...ubigeoAction,
  ...tipoServicioTuristico,
}

const getters = { ...sedeGetter }

const mutations = { ...sedeMutation }

const state = { ...sedeState }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
