import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import Auth from './modules/auth/index'
import Plataforma from './modules/plataforma/index'
import Multimedia from './modules/multimedia/index'
import Catalogo from './modules/catalogo'
import Seguridad from './modules/seguridad'
import Festividades from './modules/festividades'
import Herramienta from './modules/herramienta'
import Panel from './modules/panel'
import Tablero from './modules/tablero'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    auth: Auth,
    plataforma: Plataforma,
    multimedia: Multimedia,
    catalogo: Catalogo,
    seguridad: Seguridad,
    festividades: Festividades,
    herramienta: Herramienta,
    panel: Panel,
    tablero: Tablero,
  },
  strict: process.env.DEV,
})
