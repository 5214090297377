import apiCall from '../../index'
import apiCallPi from '../../othercx'
import config from '../../config'

const apiService = `/${config.contextBO}/api/auth`
const apiServicePi = `/${config.contextPI}/api/auth`

const service = {
  createToken(model) {
    return new Promise((resolve, reject) => {
      apiCall
        .post(apiService, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  createTokenPI(model) {
    return new Promise((resolve, reject) => {
      apiCallPi
        .post(apiServicePi, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
