import axios from 'axios'
import config from './config'

const { baseURLPI } = config
const axiosConfig = {
  baseURL: baseURLPI,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
  },
}
const apiCallPi = axios.create(axiosConfig)
export default apiCallPi
