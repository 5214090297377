export default [
  {
    path: '/app/administracion/clasificacion',
    name: 'rt-administracion-clasificacion',
    component: () => import('@/views/erp/administracion/clasificacion/ClasificacionList.vue'),
    meta: {
      resource: 'rt-administracion-clasificacion',
      pageTitle: 'Clasificacion',
      breadcrumb: [
        {
          text: 'Administración',
        },
        {
          text: 'Clasificacion',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/plataforma/contenido',
    name: 'rt-plataforma-contenido',
    component: () => import('@/views/erp/plataforma/contenido/ModerarContenido.vue'),
    //   component: () => import('@/views/erp/clasificacion/ClasificacionList.vue'),
    meta: {
      resource: 'rt-plataforma-contenido',
      pageTitle: 'Moderar Contenido',
      breadcrumb: [
        {
          text: 'Administración',
        },
        {
          text: 'Moderar Contenido',
          active: true,
        },
      ],
    },
  },
]
