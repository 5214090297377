import service from '@/services/modules/panel/atractivo'

export default {
  ATRACTIVO_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service
        .findAll(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  ATRACTIVO_FIND_BY_ID(_, params) {
    return new Promise((resolve, reject) => {
      service
        .findById(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  ATRACTIVO_CREATE(_, params) {
    return new Promise((resolve, reject) => {
      service
        .create(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  ATRACTIVO_UPDATE(_, params) {
    return new Promise((resolve, reject) => {
      service
        .update(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  ATRACTIVO_DELETE(_, params) {
    return new Promise((resolve, reject) => {
      service
        .delete(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  ATRACTIVO_CHECK_DETALLES(_, params) {
    return new Promise((resolve, reject) => {
      service
        .checkDetalles(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  ATRACTIVO_GET_ACCESOS(_, params) {
    return new Promise((resolve, reject) => {
      service
        .getAccesos(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  ATRACTIVO_GET_RUTA_ACCESOS(_, params) {
    return new Promise((resolve, reject) => {
      service
        .getRutaAccesos(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  ATRACTIVO_GET_TIPO_INGRESO(_, params) {
    return new Promise((resolve, reject) => {
      service
        .findByIdTipoIngreso(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  ATRACTIVO_GET_EPOCA_VISITA(_, params) {
    return new Promise((resolve, reject) => {
      service
        .getEpocaVisita(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  ATRACTIVO_GET_INFRAESTRUCTURA(_, params) {
    return new Promise((resolve, reject) => {
      service
        .getInfraestructura(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  ATRACTIVO_GET_SERVICIOS(_, params) {
    return new Promise((resolve, reject) => {
      service
        .getServicios(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  ATRACTIVO_GET_ACTIVIDADES(_, params) {
    return new Promise((resolve, reject) => {
      service
        .getActividades(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  ATRACTIVO_CREATE_TIPO_INGRESO(_, params) {
    return new Promise((resolve, reject) => {
      service
        .createTipoIngreso(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  ATRACTIVO_CREATE_EPOCA_VISITA(_, params) {
    return new Promise((resolve, reject) => {
      service
        .createEpocaIngreso(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
