import service from '@/services/modules/panel/promociones'

export default {
  PROMOCIONES_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service
        .findAllPromociones(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  PROMOCIONES_CREATE(_, params) {
    return new Promise((resolve, reject) => {
      service
        .create(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  PROMOCIONES_UPDATE(_, params) {
    return new Promise((resolve, reject) => {
      service
        .updateAll(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  PROMOCIONES_DELETE(_, params) {
    return new Promise((resolve, reject) => {
      service
        .delete(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
