import { io } from 'socket.io-client'

class SocketioService {
  socket;

   baseUrl = 'https://socket-chat.visitasanmartin.pe';
   // baseUrl = 'http://63.250.33.18:8081';

   constructor() {
     console.log('')
   }

   setupSocketConnection(id) {
     this.socket = io(this.baseUrl, { query: { token: id } })
     this.socket.on('connect', () => {

     })
     this.socket.on('error', err => {
       console.log('error', err)
     })
   }

   disconnect() {
     if (this.socket) {
       this.socket.disconnect()
       this.socket.on('disconnect', () => {
         console.log('desconect')
       })
     }
   }

   /* listenMessages() {
    this.socket.on('receive_message', payload => {
      console.log('socket', payload)
      return payload
    })
  } */

   sendMessage(message, idChat) {
     this.socket.emit('send_message', { receiverChatID: '1', content: message, idChat })
   }
}

export default new SocketioService()
