import service from '@/services/modules/herramienta/herramienta'

export default {
  HERRAMIENTA_GET_QR(_, params) {
    return new Promise((resolve, reject) => {
      service
        .getQR(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
