import apiCall from '../../index'
import config from '../../config'

const apiService = `/${config.contextBO}/api/v1/multimedia`
const authorization = 'Authorization'
const tipoToken = 'Bearer'
const service = {
  byUrl(params) {
    const query = `/byUrl?isthumb=${params.isthumb}&url=${params.url}`
    return new Promise((resolve, reject) => {
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findBySeccion(params) {
    const query = `/bySeccion?seccion=${params.seccion}&idSeccion=${params.idSeccion}`
    return new Promise((resolve, reject) => {
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  create(model) {
    const formData = new FormData()

    formData.append('idMultimedia', 0)
    formData.append('idSeccion', 1)
    formData.append('idx', 1)
    formData.append('orientacion', 'H')
    formData.append('seccionMult', 'DESTINOBANNER')
    formData.append('orientacion', 'H')
    formData.append('archivo', model)
    return new Promise((resolve, reject) => {
      apiCall
        .post(apiService, formData)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  delete(params) {
    return new Promise((resolve, reject) => {
      apiCall
        .delete(`${apiService}/${params.id}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  sort(params) {
    const query = `/sort?${params.multimedias}`
    return new Promise((resolve, reject) => {
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  createMultimedia(formData) {
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService, formData)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
