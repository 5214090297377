import apiCallPi from '../../othercx'
import config from '../../config'

const apiService = `/${config.contextPI}/api/v1/mensajeria`
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  searchListChat(params) {
    const user = JSON.parse(localStorage.getItem('userData'))
    const query = `/chat?limit=${params.limit}&page=${params.page}&idUsuario=${user.id}&estadoChat=${params.estado}&tipoUsuario=EMPRESA`
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  searchChatListMessage(params) {
    const query = `/mensaje?sortBy=${params.sortBy}&limit=${params.limit}&page=${params.page}&query=${params.query}&idChat=${params.idChat}`
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  create(model) {
    // const user = JSON.parse(localStorage.getItem('userData'))
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .post(`${apiService}/mensaje`, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  update(model) {
    const query = `/mensaje?etapa=${model.etapa}&idMensaje=${model.idMensaje}`
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .put(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  delete(params) {
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .delete(`${apiService}/${params.id}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}
export default service
