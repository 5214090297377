import service from '@/services/modules/festividades/festividades'

export default {
  FESTIVIDADES_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service
        .findAll(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FESTIVIDADES_FIND_BY_ID(_, params) {
    return new Promise((resolve, reject) => {
      service
        .findOneFestividad(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FESTIVIDADES_CREATE(_, params) {
    return new Promise((resolve, reject) => {
      service
        .create(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FESTIVIDADES_UPDATE(_, params) {
    return new Promise((resolve, reject) => {
      service
        .update(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FESTIVIDADES_DELETE(_, params) {
    return new Promise((resolve, reject) => {
      service
        .delete(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  FESTIVIDADES_POST_MULTIMEDIA(_, params) {
    return new Promise((resolve, reject) => {
      service
        .postMultimedia(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
