import apiCall from '../../index'
import config from '../../config'

const apiService = `/${config.contextBO}/api/v1/atractivo`
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAll(params) {
    const query = `?idDestino=${params.idDestino}&limit=${params.limit}&page=${params.page}&query=${params.query}&sortBy=${params.sortBy}`
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findById(params) {
    const query = `/${params.id}`
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  create(model) {
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  update(model) {
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .put(apiService, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  delete(params) {
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .delete(`${apiService}/${params.id}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  createTipoIngreso(model) {
    const query = '/tipo_ingreso'
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  createEpocaIngreso(model) {
    const query = '/epoca_visita'
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findByIdTipoIngreso(params) {
    const query = `/tipo_ingreso?idAtractivo=${params.id}`
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  checkDetalles(params) {
    const query = `/checkDetalles?idAtractivo=${params.idAtractivo}&idsClasificacion=${params.idsClasificacion}&seccion=${params.seccion}`
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  getAccesos(params) {
    const query = `/getAccesos?idAtractivo=${params.idAtractivo}`
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  getRutaAccesos(params) {
    const query = `/ruta_acceso?idAtractivo=${params.idAtractivo}`
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  getInfraestructura(params) {
    const query = `/getInfraestructura?idAtractivo=${params.idAtractivo}`
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  getActividades(params) {
    const query = `/getActividades?idAtractivo=${params.idAtractivo}`
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  getServicios(params) {
    const query = `/getServicios?idAtractivo=${params.idAtractivo}`
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  getEpocaVisita(params) {
    const query = `/epoca_visita?idAtractivo=${params.idAtractivo}`
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
