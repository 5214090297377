import apiCallPi from '../../othercx'
import config from '../../config'

const apiService = `/${config.contextPI}/api/v1/interaccion/review`
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAllReview(params) {
    /* &sortBy=${params.sortBy} */
    const query = `?estadoPublicacion=${params.estadoPublicacion}&limit=${params.limit}&page=${params.page}&query=${params.query}`
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  update(model) {
    const user = JSON.parse(localStorage.getItem('userData'))
    const query = `/${model.idReview}/updateEstado/${model.estado}?reasons=${model.reasons}&idUsuario=${user.id}`
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .put(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}
export default service
