import apiCallPi from '../../othercx'
import config from '../../config'

const apiService = `/${config.contextPI}/api/v1/interaccion/reserva`
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAllReservas(params) {
    /* &sortBy=${params.sortBy} */
    const query = `?limit=${params.limit}&page=${params.page}&query=${params.query}&idDirectorio=${params.idDirectorio}&ventaConcretada=${params.ventaConcretada}&fecha=${params.fecha}`
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  create(model) {
    // const user = JSON.parse(localStorage.getItem('userData'))
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .post(apiService, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  update(model) {
    const query = `?idReserva=${model.idReserva}&monto=${model.monto}`
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .put(apiService + query/* { params: { idReserva: model.idReserva, monto: model.monto } } */)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  delete(params) {
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .delete(`${apiService}/${params.id}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}
export default service
