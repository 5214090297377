import apiCallPi from '../../othercx'
import config from '../../config'

const apiService = `/${config.contextPI}/api/v1/administracion/promocion`
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAllPromociones(params) {
    /* &sortBy=${params.sortBy} */
    const query = `?estadoPromociones=${params.estadoProducto}&limit=${params.limit}&page=${params.page}&query=${params.query}`
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  create(model) {
    // const user = JSON.parse(localStorage.getItem('userData'))
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .post(apiService, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  update(model) {
    const user = JSON.parse(localStorage.getItem('userData'))
    const query = `/${model.idProducto}/updateEstado/${model.estado}?reasons=${model.reasons}&idUsuario=${user.id}`
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .put(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  updateAll(model) {
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .put(apiService, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  delete(params) {
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .delete(`${apiService}/${params.id}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}
export default service
