// import actions

import contenidoAction from './actions/contenido'
import productoAction from './actions/producto'
import reviewsAction from './actions/reviews'
import empresaAction from './actions/empresa'
import atractivoAction from './actions/atractivo'
import promocionesAction from './actions/promociones'
import reservasAction from './actions/reservas'
import chatAction from './actions/chat'

// import getters

// import mutations

// import states

const actions = {
  ...reviewsAction,
  ...productoAction,
  ...contenidoAction,
  ...empresaAction,
  ...atractivoAction,
  ...promocionesAction,
  ...reservasAction,
  ...chatAction,
}

const getters = {}

const mutations = {}

const state = {}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
