import axios from 'axios'
import config from './config'

const { pathRepo } = config
// const url = 'https://api-pi.visitasanmartin.pe/'
const axiosConfig = {
  baseURL: pathRepo,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
  },
}
const apiCall = axios.create(axiosConfig)
export default apiCall
