import service from '@/services/modules/panel/reviews'

export default {
  REVIEWS_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service
        .findAllReview(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  REVIEWS_UPDATE(_, params) {
    return new Promise((resolve, reject) => {
      service
        .update(params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}
