import service from '@/services/modules/panel/empresa'

export default {
  EMPRESA_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service.findAll(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_FIND_ALL_SERV_ADIC_DIRECTORIO() {
    return new Promise((resolve, reject) => {
      service.findAllServicioAdicionalByDirectorio().then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_CREATE(_, params) {
    return new Promise((resolve, reject) => {
      service.create(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_UPDATE(_, params) {
    return new Promise((resolve, reject) => {
      service.update(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_DELETE(_, params) {
    return new Promise((resolve, reject) => {
      service.delete(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_DELETE_BROCHURE(_, params) {
    return new Promise((resolve, reject) => {
      service.deleteBrochure(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_CHECK_DETALLES_UPDATE(_, params) {
    return new Promise((resolve, reject) => {
      service.updateServicioAdicional(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_FIND_BY_RUC(_, params) {
    return new Promise((resolve, reject) => {
      service.findByUsername(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_UPDATE_DESCRIPCION(_, params) {
    return new Promise((resolve, reject) => {
      service.updateDescripcion(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_UPDATE_BROCHURE(_, params) {
    return new Promise((resolve, reject) => {
      service.updateBrochure(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_UPDATE_CONTACTO(_, params) {
    return new Promise((resolve, reject) => {
      service.updateContacto(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_UPDATE_VIDEO(_, params) {
    return new Promise((resolve, reject) => {
      service.updateVideoPresentacion(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_UPDATE_HORARIO(_, params) {
    return new Promise((resolve, reject) => {
      service.updateHorario(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_GET_DATO_ADICIONAL(_, params) {
    return new Promise((resolve, reject) => {
      service.findDatoAdicionalByRubro(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_POST_DATO_ADICIONAL(_, params) {
    return new Promise((resolve, reject) => {
      service.updateDatosAdicionalesByRubro(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}
