// import actions
import herramientaAction from './actions/herramienta'

// import getters

// import mutations

// import states

const actions = { ...herramientaAction }

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
