import apiCall from '../../index'
import config from '../../config'

const apiService = `/${config.contextBO}/api/v1/directorio`
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findByUsername(params) {
    const query = `/getDirectorioByRuc?ruc=${params.ruc}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findAll(params) {
    const query = `?urlSlugDirectorio=${params.urlSlugDirectorio}&idDestino=${params.idDestino}&idTipoDirectorio=${params.idTipoDirectorio}&limit=${params.limit}&page=${params.page}&query=${params.query}&sortBy=${params.sortBy}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findAllServicioAdicionalByDirectorio() {
    /* =${params.sortBy} */
    const directorio = JSON.parse(localStorage.getItem('empresa'))

    const query = `/servicio_adicional?idDirectorio=${directorio.idDirectorio}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  create(model) {
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  update(model) {
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .put(apiService, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  deleteBrochure(params) {
    const query = `/deleteBrochure/${params.id}`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .delete(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  delete(params) {
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .delete(`${apiService}/${params.id}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  updateContacto(model) {
    const query = '/updateContacto'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .put(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  updateBrochure(model) {
    const query = '/updateBrochure'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .put(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  updateDescripcion(model) {
    const query = '/updateDescripcion'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  updateServicioAdicional(model) {
    const directorio = JSON.parse(localStorage.getItem('empresa'))
    const query = `/servicio_adicional/checkDetalles?idDirectorio=${directorio.idDirectorio}&seccion=AMENIDADES_SERVTUR`
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query, { params: { idsClasificacion: model.ids } })
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  updateVideoPresentacion(model) {
    const query = '/updateVideo'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .put(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  updateHorario(model) {
    const query = '/updateHorario'
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .put(apiService + query, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findDatoAdicionalByRubro(params) {
    let query = ''
    switch (params.tipo) {
      case 'AGENCIA':
        query = '/dato_adicional_agencia'
        break
      case 'RESTAURANT':
        query = '/dato_adicional_restaurante'
        break
      case 'HOSPEDAJE':
        query = '/dato_adicional_hospedaje'
        break
      case 'AGENCIA DE VIAJES Y TURISMO':
        query = '/dato_adicional_agencia'
        break
      case 'GUIAS TURISTICOS':
        query = '/dato_adicional_agencia'
        break
      case 'ARTESANIA':
        query = '/dato_adicional_artesania'
        break
      default:
    }
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query, { params: { idDirectorio: params.idDirectorio } })
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  updateDatosAdicionalesByRubro(params) {
    let query = ''
    switch (params.tipo) {
      case 'AGENCIA':
        query = '/dato_adicional_agencia'
        break
      case 'RESTAURANT':
        query = '/dato_adicional_restaurante'
        break
      case 'HOSPEDAJE':
        query = '/dato_adicional_hospedaje'
        break
      default:
    }
    return new Promise((resolve, reject) => {
      apiCall
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService + query, params.model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
